import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { IToken } from '../../interfaces/token.interface';
import { FormGroupConfig } from '../../typings/form-config.typing';
import { UserTypeEnum } from '../../enums/user-type.enum';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { IUser } from '../../interfaces/user.interface';

@Component({
  selector: 'hadsup-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  registerForm: FormGroup | any;
  isLoading: boolean = false;
  userProfile: IUser = {} as IUser;
  registerFormConfig: FormGroupConfig<any> = {
    user_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    password_confirmation: ['', Validators.required],
    country_id: [1],
    user_type: [UserTypeEnum.Sales],
    terms_accepted: [true, Validators.required]
  };
  isPassword: boolean = false;
  passwordType = 'password';
  submitted = false;
  UserTypeEnum=UserTypeEnum;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private router: Router,
    private profileService: ProfileService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.storageService.removeProfileInfo();
    this.storageService.removeUserInfo();
    this.storageService.removeAccessToken();
    this.registerForm.get('password')?.valueChanges.subscribe((password: any) => {
      this.registerForm.get('password_confirmation')?.setValue(password)
    });
  }

  initForm() {
    this.registerForm = this.fb.group(this.registerFormConfig);
  }
  get f() {
    return this.registerForm.controls;
  }
  register() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.isLoading = false;
      return;
    }
    const submittedForm = this.registerForm.value;
    submittedForm.email = submittedForm.email.toLowerCase();
    if (this.registerForm.valid) {
      this.authService.register(submittedForm).subscribe(
        (response: any) => {
          if (response) {
            setTimeout(() => {
              this.setSessionValues(response);
              this.toastrService.success("Successfully register", 'Success');
              this.profileService
                .GetProfile()
                .subscribe((res: any) => {
                  this.userProfile = res.data;
                  this.storageService.setProfileInfo(this.userProfile);
                  this.navigateToWebApp();
                });
            }, 2000);
            this.setSessionValues(response);
            this.isLoading = false;
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
      this.registerForm.markAllAsTouched();
    }
  }

  setSessionValues(response: any) {
    const Token = {} as IToken;
    Token.access_token = response.access_token;
    Token.expires_in = Date.now() + response.expires_in * 1000;
    Token.refresh_token = response.refresh_token;
    this.storageService.removeAccessToken();
    this.storageService.setAccessToken(Token);
    this.storageService.setUserInfo(response.data);
  }

  onlyAlphabetValidation(event: any) {
    const inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  passwordHideShow(type: string) {
    if (type === 'PASSWORD') {
      this.isPassword = !this.isPassword;
      this.passwordType = this.isPassword ? 'text' : 'password';
    }
  }
  navigateToWebApp() {
    const isSalesUser = this.userProfile.relations.roles.some(role => role.id === UserTypeEnum.Sales);
    const targetRoute = isSalesUser ? '/overview' : '/login';
    this.router.navigateByUrl(targetRoute);
  }
  setRole(user_type: UserTypeEnum) {
    this.registerForm.patchValue({ user_type: user_type });
  }
}
