import { Component,OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { IUser } from '../../interfaces/user.interface';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { LoginModel } from '../../models/login.model';
import { IToken } from '../../interfaces/token.interface';
import { Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'hadsup-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading: boolean = false;
  isPassword: boolean = false;
  userProfile: IUser = {} as IUser;
  screenType = 'login';
  passwordType = 'password';
  submitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private profileService: ProfileService,
    private toastr: ToastrService,
  ) {
    let userInformation = {
      email: '',
      password: '',
    };
    this.loginForm = fb.group({
      email: new FormControl(userInformation.email, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      password: new FormControl(userInformation.password, [Validators.required, Validators.minLength(8)]),
    });
  }
  
  ngOnInit(): void {
    this.storageService.removeAllWithoutDevice();
  }

  get f() {
    return this.loginForm.controls;
  }
  login() {
    this.storageService.removeAll()
    if (this.isLoading) {
      this.submitted = false;
      return;
    }
    this.submitted = true;
    if (this.loginForm.valid) {
      this.isLoading = true;
      const submittedForm: any = this.loginForm.value as LoginModel;
      this.isLoading = true;
      this.authService.Login(submittedForm).subscribe(
        (response) => {
          if (response) {
            if(response.data.permissions && response.data.permissions.includes('sales')){
              this.toastr.success("You are successfully logged in", 'Success')
              setTimeout(() => {
                this.setSessionValues(response); 
                this.router.navigate(['/overview']); 
              }, 1000);
            }else{
              this.toastr.error("You are not authorized", 'Error')
            }
            this.isLoading = false;
          }
        },
        (error: any) => {
          this.toastr.error(error.error.errors[0].errors, 'Error')
          this.submitted = false;
          this.isLoading = false;
          this.storageService.removeAccessToken()
        }).add(() => {
          this.isLoading = false;
          this.submitted = false;
        });
    }
  }

  setSessionValues(response: any) {
    const Token = {} as IToken;
    Token.access_token = response.access_token;
    Token.expires_in = Date.now() + response.expires_in * 1000;
    Token.refresh_token = response.refresh_token;
    this.storageService.removeAccessToken();
    this.storageService.setAccessToken(Token);
    this.storageService.setUserInfo(response.data);
    this.profileService.GetProfile().subscribe((res: any) => {
      localStorage.setItem('User', JSON.stringify(res.data));
      localStorage.setItem('Profile', JSON.stringify(res.data));
    });
  }
  passwordHideShow(type: string) {
    if (type === 'PASSWORD') {
      this.isPassword = !this.isPassword;
      this.passwordType = this.isPassword ? 'text' : 'password';
    }
  }
}
