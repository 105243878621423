<div class="form-container">
  <div class="form-card">
    <div class="form-group logo">
      <img src="/assets/images/hadsup-logo.svg">
    </div>
    <h3>Create an account</h3>
    <p>Welcome to HadsUp Sales.</p>
    <form [formGroup]="registerForm" (ngSubmit)="register()">
      <div class="form-group">
        <label for="name">Name</label>
        <input id="name" formControlName="user_name" type="text" placeholder="Enter your name" maxlength="18"
          (keypress)="onlyAlphabetValidation($event)" />
        <div *ngIf="submitted && f?.user_name.errors" class="error">
          <div class="error" *ngIf="f?.user_name.errors.required"> The name field is required </div>
          <div class="error" *ngIf="f.user_name.errors.pattern"> Follow your pattern !only using alphabet </div>
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" formControlName="email" autocomplete="off" maxlength="40" type="email"
          placeholder="Enter your email" />
        <div *ngIf="submitted && f.email.errors">
          <div class="error" *ngIf="f.email.errors && f.email.errors.required"> The email field is required</div>
          <div class="error" *ngIf="f.email.errors.pattern">Enter the valid email address </div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="wrapper">
          <input id="password" formControlName="password" type="password" placeholder="Create a password"
            type="{{ passwordType }}" />
          <span class="eye-icon"><i class="uil {{ isPassword ? 'uil-eye-slash' : 'uil-eye' }} s-21"
              (click)="passwordHideShow('PASSWORD')"></i></span>
        </div>
        <div *ngIf="submitted && f.password.errors">
          <div class="error" *ngIf="f.password.errors.required"> The password field is required </div>
          <div class="error" *ngIf="f.password.errors.minlength"> Password must be minimum 8 characters in length </div>
        </div>
        <div class="form-actions">
          <p class="alert-message">Must be at least 8 characters.</p>
        </div>
        <div class="role-action">
          <div class="role-action-box sales" (click)="setRole(UserTypeEnum.Sales)">
            <div class="title-wrap">
              <span class="icon-img"><img src="/assets/icons/advertisers-icon.svg" alt="" /></span>
              <p class="role-title">Sales Agent</p>
            </div>
          </div>
          <div class="role-action-box house">
            <div class="title-wrap">
              <span class="icon-img"><img src="/assets/icons/sales-house.svg"></span>
              <p class="role-title">Sales House</p>
              <span class="sub-title">Coming Soon</span>
            </div>
          </div>
        </div>
        <div class="form-actions form-action-bottom">
          <button type="submit" class="action-btn">
            <span *ngIf="!isLoading">Get started</span>
            <div class="button-loading" *ngIf="isLoading">
              <span class="button-text">Loading...</span>
            </div>
          </button>
        </div>
        <p class="page-link">Already have an account? <a [routerLink]="['/login']">Log in</a></p>
      </div>
    </form>
  </div>
</div>