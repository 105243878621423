import { HttpParameterCodec, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranactionService {
  rootUrl = `${environment.apiUrl}/api/v1/`;
  public encoder: HttpParameterCodec | undefined;
  constructor(private http: HttpClient) { }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
      if (typeof value === "object" && value instanceof Date === false) {
          httpParams = this.addToHttpParamsRecursive(httpParams, value);
      } else {
          httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
      }
      return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
      if (value == null) {
          return httpParams;
      }

      if (typeof value === "object") {
          if (Array.isArray(value)) {
              (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
          } else if (value instanceof Date) {
              if (key != null) {
                  httpParams = httpParams.append(key,
                      (value as Date).toISOString().substr(0, 10));
              } else {
                  throw Error("key may not be null if value is Date");
              }
          } else {
              Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
                  httpParams, value[k], key != null ? `${key}.${k}` : k));
          }
      } else if (key != null) {
          httpParams = httpParams.append(key, value);
      } else {
          throw Error("key may not be null if value is not object or array");
      }
      return httpParams;
  }

  Transactions(page: number, limit: number, sort: string, filter: any) {
      const relativeUrl: string = 'transactions?';
      const url: string = this.rootUrl + relativeUrl;
      let queryParameters = new HttpParams({ encoder: this.encoder });
      if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
              <any>page, 'page');
      }

      if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
              <any>limit, 'limit');
      }

      if (sort !== undefined && sort !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
              <any>sort, 'sort');
      }

      if (filter !== undefined && filter !== null) {
          queryParameters = queryParameters.append('filter', filter);
      }

      return this.http.get(url, {
          params: queryParameters
      });
  }

  sendPaymentInstructions(data: any) {
      const relativeUrl: string = 'user/userId/sendPaymentDetails';
      const url: string = this.rootUrl + relativeUrl;
      return this.http.post(url, data);
  }

  Withdraw(data: any) {
      const MethodName: string = 'transactions';
      const url: string = this.rootUrl + MethodName;
      return this.http.post(url, data);
  }

  GetUserAccountDetails() {
      const MethodName: string = 'user-account-details';
      const url: string = this.rootUrl + MethodName;
      return this.http.get(url);
  }

  CreateUserAccountDetails(data: any) {
      const MethodName: string = 'user-account-details';
      const url: string = this.rootUrl + MethodName;
      return this.http.post(url, data);
  }

  UpdateUserAccountDetails(data: any) {
      const MethodName: string = 'user-account-details/update';
      const url: string = this.rootUrl + MethodName;
      return this.http.post(url, data);
  }

  DeleteUserAccountDetails(data: any) {
      const MethodName: string = 'user-account-details';
      const url: string = this.rootUrl + MethodName;
      return this.http.delete(url, data);
  }

  PaymentSend(data: any) {
      const MethodName: string = 'payment/create-checkout-session';
      const url: string = this.rootUrl + MethodName;
      return this.http.post(url, data);
  }

  PaymentPaypalSend(data: any) {
      const MethodName: string = 'payment/paypal-topup-request';
      const url: string = this.rootUrl + MethodName;
      return this.http.post(url, data);
  }

  PaymentBankTransfer(data: any) {
      const MethodName: string = 'payment/wise-topup-request';
      const url: string = this.rootUrl + MethodName;
      return this.http.post(url, data);
  }

  Transfer(data: any) {
      const MethodName: string = 'balance-transfer';
      const url: string = this.rootUrl + MethodName;
      return this.http.post(url, data);
  }


}
