<div class="form-container">
  <div class="form-card">
    <div class="form-group logo">
      <img src="/assets/images/hadsup-logo.svg">
    </div>
    <h3>Log in to your account</h3>
    <p>Welcome back! Please enter your details.</p>
    <form [formGroup]="loginForm">
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" formControlName="email" type="email" placeholder="Enter your email" formControlName="email" />
        <div *ngIf="submitted && f['email'].errors">
          <div class="error" *ngIf="f['email'].errors && f['email'].errors['required']"> The email field is required </div>
          <div class="error" *ngIf="f['email'].errors['pattern']"> Email must be a valid email address </div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="wrapper">
          <input id="password" formControlName="password" type="password" placeholder="Enter your password"
            [type]="passwordType" formControlName="password" />
            <div *ngIf="submitted && f['password'].errors">
              <div class="error" *ngIf="f['password'].errors && f['password'].errors['required']"> The password field is required</div>
              <div class="error" *ngIf="f['password'].errors['pattern']"> Enter the valid email address </div>
            </div>
          <span class="eye-icon"><i class="uil {{
            isPassword
                ? 'uil-eye-slash'
                : 'uil-eye'
        }} s-21" (click)="passwordHideShow('PASSWORD')"></i></span>
        </div>
      </div>
      <div class="form-group forgot-password">
        <a routerLink="/forgot-password" class="forgot-password">Forgot password</a>
      </div>
      <div class="form-actions">
        <button class="action-btn" (click)="login()">
          <span *ngIf="!isLoading">Sign in</span>
          <div class="button-loading" *ngIf="isLoading">
            <span class="button-text">Loading...</span>
          </div>
        </button>
      </div>
      <p class="page-link">Don’t have an account? <a routerLink="/register">Sign up</a></p>
    </form>
  </div>
</div>