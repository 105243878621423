import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  inject,
  Renderer2,
} from '@angular/core';
import {
  ValidatorFn,
  AbstractControl,
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserTypeEnum } from '../../enums/user-type.enum';
import {
  IEarningByDate,
  IUserBalance,
  IBalance,
} from '../../interfaces/generic.interface';
import {
  ITransactions,
  IAccountDetails,
} from '../../interfaces/transaction.interface';
import { IUser, UserTypeName } from '../../interfaces/user.interface';
import { PagingMetaModel } from '../../models/paging-meta.model';
import { ProfileService } from '../../services/profile.service';
import { CustomCalendarHeaderComponent } from '../custom-calendar-header/custom-calendar-header.component';
import { TranactionService } from '../../services/tranaction.service';
import { TransactionModel } from '../../models/transaction.model';
import { MatDialog } from '@angular/material/dialog';

declare let $: any;

export function amountValidator(parent: TransactionsComponent): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const invalidBalanceError =
      Number(control.value) > parent?.showUserRoleBalance;
    return invalidBalanceError
      ? { invalidAmount: 'Please check your current balance' }
      : null;
  };
}
@Component({
  selector: 'hadsup-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  providers: [DatePipe],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  minDate = new Date(2010, 0, 1);
  maxDate = new Date(new Date().setDate(new Date().getDate()));
  start: any;
  end: any;
  activeOption: string = '';
  total_earnings: number = 0;
  customCalendarHeader = CustomCalendarHeaderComponent;
  module: string = '';
  alive: boolean = false;
  private subscriptions = new Subscription();
  loadingData = false;
  userBalanceloadingData = false;
  profileLoadingData = false;
  userProfile: IUser = {} as IUser;
  transactions: ITransactions[] = [];
  balance: number = 0;
  currencySign: string = '';
  totalEarnings: number = 0;
  earnings: IEarningByDate[] = [];
  serverDateFormat = 'yyyy-MM-dd';
  userBalance: IUserBalance = {} as IUserBalance;
  userRoleBalance: any[] = [];
  showUserRoleBalance: any = '';
  allUserRoleBalance: number = 0;
  isBalance = false;
  lastInvest: ITransactions = {} as ITransactions;
  lastWithdraw: ITransactions = {} as ITransactions;
  pendingTransaction: number = 0;
  pagingModel: PagingMetaModel = new PagingMetaModel();
  withdrawFormValid: boolean = false;
  submitted: boolean = false;
  withdrawForm: any = FormGroup;
  transferForm: FormGroup;
  userAccountDetails: IAccountDetails = {} as IAccountDetails | any;
  transactionUserRole: UserTypeEnum[] = [];
  userRoles: number = 17;
  currentBalance: IBalance = {} as IBalance;
  withdrawFroms: any = { value: '', userTypeName: 'Select withdraw from' };
  withdrawFromsList: any = [];
  transferFroms: any = { value: '', userTypeName: '0' };
  transferList: any = [];
  transferFromList: any = [];
  transferToList: any = [];
  transferFromSelect: any = 0;
  transferToSelect: any = 0;
  transferFormValid: boolean = false;
  transferRoleBalance: number = -1;
  transferAmount: number = 0;
  filterApplied: boolean = false;
  isClassAdded: boolean = false;
  currentClass: string = 'overflow-hidden-model';
  readonly withdrawDialog = inject(MatDialog);
  withdrawTypeList: any = [
    { withdrawTypeValue: 'CASH_BACK', withdrawTypeLabel: 'Cashback' },
    { withdrawTypeValue: 'WITHDRAW', withdrawTypeLabel: 'Withdraw' },
    { withdrawTypeValue: 'TOP_UP', withdrawTypeLabel: 'Deposit' },
    {
      withdrawTypeValue: 'CAMPAIGN_CHARGE',
      withdrawTypeLabel: 'Campaign Charge',
    },
  ];
  withdrawType: any = { value: '', withdrawTypeLabel: 'Transaction Type' };
  paymentType = '';
  homePage = environment.front_url;
  loading: boolean = false;
  inputAmount = 0;
  bankTransfer: any = {
    local: {
      'Account holder': '',
      'Sort code': '',
      'Account number': '',
      IBAN: '',
      "Wise's address": '',
    },
    international: {
      'Account holder': '',
      'SWIFT/BIC': '',
      IBAN: '',
      "Wise's address": '',
    },
    reference_code: '',
    amount: '0',
  };
  loadingTransactionsData: boolean = false;

  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private transactionService: TranactionService,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) {
    this.subscriptions.add(
      this.route.data.subscribe((val: any) => {
        this.module = val.module;
      })
    );

    this.withdrawForm = fb.group({
      account_holder_name: new FormControl('', [Validators.required]),
      account_number: new FormControl('', [Validators.nullValidator]),
      sort_code: new FormControl('', [Validators.nullValidator]),
      iban_number: new FormControl('', [Validators.required]),
      saveAccountDetails: new FormControl(false, [Validators.nullValidator]),
      amount: new FormControl('', [Validators.required, amountValidator(this)]),
    });

    this.transferForm = fb.group({
      amount: new FormControl('', [Validators.required]),
    });
  }

  get withdrawFormInfo() {
    return this.withdrawForm.controls;
  }

  formValidationReload() {
    const ibanNumber = this.withdrawForm.get('iban_number')?.value;
    const accountNumber = this.withdrawForm.get('account_number')?.value;
    const sortCode = this.withdrawForm.get('sort_code')?.value;
    if (ibanNumber && ibanNumber.length > 0) {
      this.withdrawFormValid = true;
    } else if (
      accountNumber &&
      accountNumber.length > 0 &&
      sortCode &&
      sortCode.length > 0
    ) {
      this.withdrawFormValid = true;
    } else {
      this.withdrawFormValid = false;
    }
  }
  numericOnly(event: any) {
    const pattern = /[0-9+\- ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.GetUserBalanceSalesAgent();
    this.GetProfileDetails();
    this.GetTransactions();
    this.GetAccountDetails();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.alive = false;
  }

  ValidateWithdrawAmount(event: any) {
    if (this.allUserRoleBalance > event.target.value) {
      this.showUserRoleBalance = this.allUserRoleBalance;
    }
    if (!event.target.value) {
      this.showUserRoleBalance = this.allUserRoleBalance;
    }
  }

  GetTransactions(page: number | null = null) {
    if (page !== null) {
      this.pagingModel.current_page = 1;
    }
    this.loadingTransactionsData = true;
    const filter: any = {};

    // Date filters
    if (this.start && this.end) {
      const dates = {
        from: this.datePipe.transform(this.start, this.serverDateFormat),
        to: this.datePipe.transform(this.end, this.serverDateFormat),
      };
      filter['release_date'] = dates;
    } else if (this.start) {
      const date = {
        operation: 'in',
        value: [this.datePipe.transform(this.start, this.serverDateFormat)],
      };
      filter['release_date'] = date;
    }
    // Payment type filter
    if (this.start || this.end || this.paymentType) {
      this.filterApplied = true;
    }
    if (this.paymentType !== '') {
      const paymentType = {
        operation: '=',
        value: this.paymentType,
      };
      filter['type'] = paymentType;
    }

    let roleIds = [17];
    const roles = {
      operation: 'in',
      value: roleIds,
    };
    filter['transaction_user_role'] = roles;

    this.subscriptions.add(
      this.transactionService
        .Transactions(
          this.pagingModel.current_page,
          this.pagingModel.per_page,
          '-created_at',
          JSON.stringify(filter)
        )
        .subscribe((response: any) => {
          if (response) {
            this.transactions = response.data;
            const investIndex = this.transactions.findIndex(
              (f) => f.type == 'INVEST'
            );
            if (investIndex > -1) {
              this.lastInvest = this.transactions[investIndex];
            }

            const withdrawIndex = this.transactions.findIndex(
              (f) => f.type == 'WITHDRAW'
            );
            if (withdrawIndex > -1) {
              this.lastWithdraw = this.transactions[withdrawIndex];
            }

            if (this.module != 'advertiser') {
              const sum = this.transactions
                .filter(
                  (f) => f.status == 1 || f.status == 30 || f.status == 50
                )
                .reduce((sum, current) => sum + current.amount, 0);

              this.pendingTransaction = sum;
            }
            this.pagingModel = response.meta;
          }
          this.loadingTransactionsData = false;
        })
    );
  }

  NextPage() {
    if (this.pagingModel.current_page + 1 <= this.pagingModel.last_page) {
      this.pagingModel.current_page += 1;
      this.GetTransactions();
    }
  }

  PrevPage() {
    if (this.pagingModel.current_page - 1 >= 1) {
      this.pagingModel.current_page -= 1;
      this.GetTransactions();
    }
  }

  GetProfileDetails() {
    this.profileLoadingData = true;
    this.subscriptions.add(
      this.profileService.GetProfile().subscribe((response: any) => {
        if (response) {
          this.userProfile = response.data;
          this.setTotalBalance();
          this.setUserTransactionRole();
          this.currencySign = this.userProfile.relations.currency.sign;
        }
        this.profileLoadingData = false;
      })
    );
  }

  GetUserBalanceSalesAgent() {
    this.userBalanceloadingData = true;
    this.subscriptions.add(
      this.profileService
        .GetUserBalance(this.userRoles)
        .subscribe((response: any) => {
          if (response) {
            this.userRoleBalance = response.data;
            this.userBalance = response.data[0];
            this.pendingTransaction = response.data[0].hold;
          }
          this.userBalanceloadingData = false;
        })
    );
  }

  setUserTransactionRole() {
    if (this.userProfile.relations.roles) {
          if (this.userProfile.relations.roles.findIndex((f) => f.id == UserTypeEnum.Sales) > -1){
            this.withdrawFromsList.push({
              withdrawFrom: UserTypeEnum.Sales,
              userTypeName: UserTypeName.Sales,
            });
          }
    }
  }

  setTotalBalance() {
    this.balance = this.userProfile.relations.balance.balance || 0;
    this.currentBalance = this.userProfile.relations.balance;
  }

  GetAccountDetails() {
    this.subscriptions.add(
      this.transactionService.GetUserAccountDetails().subscribe(
        (response: any) => {
          if (response) {
            this.userAccountDetails = response.data;
            if (this.userAccountDetails && this.userAccountDetails.id) {
              this.withdrawForm.controls['account_holder_name'].setValue(
                this.userAccountDetails.account_holder_name
              );
              this.withdrawForm.controls['account_number'].setValue(
                this.userAccountDetails.account_number
              );
              this.withdrawForm.controls['sort_code'].setValue(
                this.userAccountDetails.sort_code
              );
              this.withdrawForm.controls['iban_number'].setValue(
                this.userAccountDetails.iban_number
              );
              this.withdrawForm.controls['saveAccountDetails'].setValue(true);
            }
          }
        },
        () => {
          if (this.userAccountDetails === null) {
            this.withdrawForm.controls['account_holder_name'].setValue('');
            this.withdrawForm.controls['account_number'].setValue('');
            this.withdrawForm.controls['sort_code'].setValue('');
            this.withdrawForm.controls['iban_number'].setValue('');
            this.withdrawForm.controls['saveAccountDetails'].setValue(false);
          }
        }
      )
    );
  }
  clearForm() {
    this.withdrawForm.reset();
    this.withdrawFroms = { value: '', userTypeName: 'Select withdraw from' };
    this.showUserRoleBalance = null;
    this.submitted = false;
    this.isBalance = false;
  }
  DoWithdraw(): any {
    if (this.submitted) {
      return; 
  }
    if (
      this.showUserRoleBalance &&
      this.showUserRoleBalance > this.withdrawForm.value.amount
    ) {
      this.withdrawForm.get('amount').clearValidators();
      this.withdrawForm.get('amount').updateValueAndValidity();
    } else if (this.withdrawForm.value.amount === null) {
      this.withdrawForm.controls['amount'].setValidators([
        Validators.required,
        amountValidator(this),
      ]);
      this.withdrawForm.get('amount').updateValueAndValidity();
    }
    this.submitted = true;
    if (this.withdrawForm.valid && this.withdrawFormValid) {
      const submittedForm: any = this.withdrawForm.value;
      if (submittedForm.saveAccountDetails) {
        if (this.userAccountDetails && this.userAccountDetails.id) {
          this.userAccountDetails.account_holder_name =
            submittedForm.account_holder_name;
          this.userAccountDetails.account_number = submittedForm.account_number;
          this.userAccountDetails.sort_code = submittedForm.sort_code;
          this.userAccountDetails.iban_number = submittedForm.iban_number;
          this.transactionService
            .UpdateUserAccountDetails(this.userAccountDetails)
            .toPromise();
        } else {
          this.userAccountDetails.account_holder_name =
            submittedForm.account_holder_name;
          this.userAccountDetails.account_number = submittedForm.account_number;
          this.userAccountDetails.sort_code = submittedForm.sort_code;
          this.userAccountDetails.iban_number = submittedForm.iban_number;
          this.transactionService
            .CreateUserAccountDetails(this.userAccountDetails)
            .toPromise();
        }
      } else {
        this.transactionService
          .DeleteUserAccountDetails(this.userAccountDetails)
          .toPromise();
      }
      const transaction: any = new TransactionModel();
      transaction.amount = submittedForm.amount;
      transaction.description =
        'Account Name: ' + submittedForm.account_holder_name + '\n ';
      transaction.description +=
        'Account Number: ' + submittedForm.account_number + '\n ';
      transaction.description +=
        'Sort Code: ' + submittedForm.sort_code + '\n ';
      transaction.description += 'IBAN: ' + submittedForm.iban_number;
      transaction.owner_id = this.userProfile.id;
      transaction.release_date = this.datePipe.transform(
        new Date(),
        this.serverDateFormat
      );
      if (!this.transactionUserRole) {
        this.toastr.error('Please select a Withdraw from', 'Error');
        return false;
      }
      transaction.transaction_user_role = this.transactionUserRole;
      this.transactionService.Withdraw(transaction).subscribe(
        (res) => {
          if (res) {
            this.GetTransactions();
            this.GetAccountDetails();
            this.closeModel();
            $('role-type').val('');
          }
        },
        (error: any) => {
          this.toastr.error(error.error.errors[0].errors[0], 'Error');
          this.withdrawFroms = {
            value: '',
            userTypeName: 'Select withdraw from',
          };
          this.submitted = false;
          this.withdrawForm.reset();
        }
      );
    }
  }


  withdrawFromsSelection(event: any) {
    this.transactionUserRole = event?.withdrawFrom;
    const index = this.userRoleBalance.findIndex(
      (f) => f.balance_user_role == event.withdrawFrom
    );
    if (index > -1) {
      this.showUserRoleBalance = this.userRoleBalance[index].balance;
      this.allUserRoleBalance = this.userRoleBalance[index].balance;
      this.isBalance = true;
      const amount: any = (document.getElementById('amount') as HTMLInputElement).value;
      if (amount) {
        if (this.showUserRoleBalance > amount) {
          this.showUserRoleBalance -= amount;
        }
      }
      
      if (
        this.showUserRoleBalance &&
        this.showUserRoleBalance > this.withdrawForm.value.amount
      ) {
        this.withdrawForm.get('amount').clearValidators();
        this.withdrawForm.get('amount').updateValueAndValidity();
      }
    }
  }
  
  withdrawTypeSelection(event: any) {
    if (this.start || this.end || event?.withdrawTypeValue) {
      this.paymentType = event?.withdrawTypeValue || '';
      this.filterApplied = false;
    } else {
      this.filterApplied = false;
    }
  }
  ResetFilters() {
    this.start = null;
    this.end = null;
    this.paymentType = '';
    this.withdrawType = { value: '', withdrawTypeLabel: 'Transaction Type' };
    this.filterApplied = false;
    this.GetTransactions();
  }

  nameValidation(event: KeyboardEvent) {
    const pattern = /[a-zA-Z ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  inputChange(event: any) {
    this.inputAmount = Number(event.target.value);
  }

  isNumber(evt: any): any {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  openWithdrawModal() {
    this.isClassAdded = true; 
    this.bodyClass(this.currentClass);
  }

  closeModel() {
    this.isClassAdded =false;
    this.renderer.removeClass(document.body, this.currentClass); 
    this.clearForm();
  }
  bodyClass(bodyClass: string): void {
    this.currentClass = bodyClass;
    this.renderer.addClass(document.body, this.currentClass);
  }
  
  closeModelOnOverlay(event: MouseEvent) {
    if (event.target === event.currentTarget) {
        this.closeModel();
        this.clearForm();
    }
}
}
