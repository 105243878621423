<div class="content-wrap">
    <div class="dropdown dropdown-select">
        <span class="label" *ngIf="!hasRadio">{{ labelName }} <sup *ngIf="isRequired">*</sup></span>
        <button class="dropdown-toggle dropdown-toggle-click" [class.button-style]="hasRadio" type="button"
            [class.active]="dropdownActive" (click)="toggleDropdown()">
            <span *ngIf="value; else noItem">
                <span *ngIf="!isMultipleSelection"> {{ nameProp === "" ? value : displayValueProp ? value[valueProp] :
                    value[nameProp] }} <span *ngIf="hasRadio"> {{ labelProp === "" ? value : displayValueProp ?
                        value[valueProp] : value[labelProp] }} </span>
                </span>
                <span *ngIf="isMultipleSelection" class="multiple-badges">
                    <span *ngIf="selectedItems.length === 0">{{ defaultText }}</span>
                    <span *ngIf="selectedItems.length === 1">
                        <span *ngFor="let item of selectedItems | slice: 0:1">{{ item?.name }}</span>
                    </span>
                    <span *ngIf="selectedItems.length > 1"> {{ selectedItems.length + " Selected" }} </span>
                </span>
            </span>
            <ng-template #noItem><span>{{ defaultText }}</span></ng-template>
            <i class="uil uil-angle-down s-21"></i>
        </button>
        <div [ngClass]="{'show': dropdownActive, 'hide': !dropdownActive}" class="dropdown-menu"
            [class.dropdown-menu-right]="hasRadio" *ngIf="!isMultipleSelection">
            <h6 *ngIf="hasRadio" class="title">{{ labelName }}</h6>
            <div *ngIf="hasRadio">
                <a (click)="selectItem(item)" class="dropdown-item fake-radio-wrap" *ngFor="let item of items"
                    [class.active]="item?.isSelected">
                    <span class="d-flex">
                        <span class="fake-radio"></span>
                        <span>{{ nameProp === "" ? item : item[nameProp] }}</span>
                    </span>
                    <span class="color-white">{{ labelProp === "" ? item : item[labelProp] }}</span>
                </a>
            </div>
            <div *ngIf="!hasRadio">
                <a (click)="selectItem(item)" class="dropdown-item" *ngFor="let item of items">{{ nameProp === "" ? item
                    : item[nameProp] }}</a>
            </div>
        </div>
        <div [ngClass]="{'show': dropdownActive, 'hide': !dropdownActive}" *ngIf="isMultipleSelection"
            class="dropdown-menu" (click)="$event.stopPropagation()">
            <div (click)="selectItem(item)" *ngFor="let item of items" class="dropdown-item">
                <mat-checkbox (change)="onMultipleSelect(item, $event)" [checked]="item?.isSelected"
                    [disabled]="isDefaultSelection" data-test-id="value-drop-down-checkbox"> {{ item.name }}
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>