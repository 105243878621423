import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import Highcharts from 'highcharts';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
noDataToDisplay(Highcharts);
@Component({
  selector: 'hadsup-ad-spot-chart',
  templateUrl: './ad-spot-chart.component.html',
  styleUrls: ['./ad-spot-chart.component.scss'],
})
export class AdSpotChartComponent implements OnChanges, AfterViewInit {
  @Input() chartId: string = '';
  @Input() chartOptions: Highcharts.Options | any = {};
  @Input() legendItems: { color: string; name: string, borderColor: string }[] = [];
  @Input() title: string = '';
  @Input() type: any;
  @Input() seriesName: string = '';
  @Input() data: number[] = [];
  @ViewChild('chartContainer', { static: true }) chartContainer:
    | ElementRef<HTMLDivElement>
    | undefined;
  public seriesData: Highcharts.SeriesOptionsType[] = [];
  public chart: Highcharts.Chart | undefined;
  public highlightedSeriesIndex: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data?.length > 0) {
      this.seriesData = this.chartOptions?.series || [];

      this.updateChartOptions();
      this.renderAreaChart();
    }
    if (changes['legendItems']) {
      this.createLegend(`${this.chartId}-legend`, this.legendItems);
    }
  }

  ngAfterViewInit(): void {
    this.updateChartOptions();
    if (this.chartContainer) {
      this.renderAreaChart();
      this.createLegend(`${this.chartId}-legend`, this.legendItems);
    }
  }

  renderAreaChart() {
    if (this.chartContainer && this.chartOptions) {
      if (!this.chart) {
        this.chart = Highcharts.chart(this.chartContainer.nativeElement, this.chartOptions);
      } else {
        this.chart.update(this.chartOptions, true, true);
      }
    }
  }
  updateChartOptions() {
    const yAxisTitle = this.chartOptions?.yAxis?.title?.text;
    const totalLabels = this.chartOptions?.xAxis?.categories?.length || 0;
    const labelsToShow = Math.min(7, totalLabels);
    this.chartOptions = {
      ...this.chartOptions,
      accessibility: {
        enabled: false
      },
      series: this.seriesData,
      title: { text: undefined },
      yAxis: {
        title: {
          text: yAxisTitle,
          style: {
            fontFamily: 'Inter',
            fontWeight: '600',
            fontSize: '12px',
            color: '#475467',
          },
        },
      },
      xAxis: {
        categories: this.chartOptions?.xAxis?.categories || [],
        title: { text: undefined },
        labels: {
          enabled: true,
          rotation: 0,
          style: {
            color: '#000000',
            fontSize: '12px',
            fontWeight: '400',
            textOverflow: 'none',
            whiteSpace: 'nowrap',
          },
          formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
            if (totalLabels > 7) {
              return this.pos % Math.ceil(totalLabels / labelsToShow) === 0 ? this.value : '';
            } else {
              return this.value;
            }
          }
        },
        tickInterval: totalLabels > 7 ? Math.ceil(totalLabels / labelsToShow) : 1,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false,
            symbol: 'circle',
            states: { hover: { enabled: false } },
          },
          states: {
            hover: {
              enabled: true,
              lineWidth: 2,
              lineColor: '#000',
            },
          },
        },
      },
      legend: {
        enabled: false,
      },
      chart: {
        marginBottom: 80,
        events: {
          render: () => {
            if (this.chartContainer) {
              this.createLegend(`${this.chartId}-legend`, this.legendItems);
            }
          },
        },
      },
    };
  }

  createLegend(
    containerId: string,
    legendItems: { color: string; name: string, borderColor: string }[]
  ) {
    const container = document.getElementById(containerId);
    if (container) {
      container.innerHTML = '';
      legendItems.forEach((item, index) => {
        const displayColor = item.borderColor || item.color;
        const legendItem = document.createElement('div');
        legendItem.className = 'chart-legend areaChart';
        legendItem.innerHTML = `
          <div class="legend-color-wrap">
            <span class="legend-color-inner"  style="background-color: ${displayColor};"></span>
            ${item.name}
          </div>
        `;
        legendItem.addEventListener('mouseover', () => this.highlightSeries(index));
        legendItem.addEventListener('mouseout', () => this.unhighlightSeries());
        container.appendChild(legendItem);
      });
    }
  }
  highlightSeries(index: number) {
    if (!this.chart) return;
    this.chart.series.forEach((series: any, i: number) => {
      if (series.name !== 'Visitors') {
        const isCurrentSeries = i === index;
        const opacity = isCurrentSeries ? 1 : 0.1;
        const zIndex = isCurrentSeries ? 10 : -1;

        series.update({ opacity, zIndex }, false);
      }
    });
    this.chart.redraw();
    setTimeout(() => this.unhighlightSeries(), 2000);
  }


  unhighlightSeries() {
    if (this.chart) {
      this.chart.series.forEach((series: any) => {
        series.update({ opacity: 1, zIndex: 0 }, false);
      });
      this.chart.redraw();
    }
  }
}
